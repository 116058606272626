function getParsedDate(date) {
    let month = (date.getMonth() + 1);
    if (month < 10) month = '0' + month;
    let day = date.getDate();
    if (day < 10) day = '0' + day;

    return date.getFullYear() + '-' + month + '-' + day;
}

$(document).on("change", "#report", function () {
    const value = $(this).val();
    // console.log(value);
    // const depth_field = $('#depth');

    const balnceSheetSelected = value == 0;
    const trialBalanceSelected = value == 3;
    const profitStatementSelected = value == 7;
    const hideFromDate = balnceSheetSelected || trialBalanceSelected || profitStatementSelected;
    $("#from_date_label").parent().toggleClass('hidden', hideFromDate);
    if (hideFromDate) {
        $("#from_date_label").val('');
    }
    // depth_field.prop("readonly", !balnceSheetSelected);
    // depth_field.parent().toggleClass('hidden', !balnceSheetSelected);

    const enableAccountNumber = value == 2;
    const el = $('#report-account-id').parent().parent();
    if (!el) return;
    if (enableAccountNumber) el.removeClass('hidden');
    else el.addClass('hidden');

    //const dailyJournalSelected = value == 5;
    //if(dailyJournalSelected){
    //$("#from_date_label").html("Date");
    //$("#to_date").parent().addClass('hidden');
    //$("#from_date").val(getParsedDate(new Date()));
    //}
    //else{
    //$("#from_date_label").html("From Date");
    //$("#to_date").parent().removeClass('hidden');
    //$("#from_date").val(getParsedDate(new Date(new Date().getFullYear(), 0, 1)));
    //}
});

/**
*
* @param {JQuery<HTMLElement>} el
*/
function reset_type_ahead(el, reset = true, text = 'Please Type To Filter Results') {
    el.val('');
    const input = el.next();
    if (reset) input.val('');
    input.next().empty().append(`<div class="px-4 placeholder"> ${text} </div>`);
}

function search_accounts(filter) {
    // console.log(filter);
    return new Promise((resolve, reject) => {
        $.ajax({
            url: '/accounts/search',
            type: 'GET',
            data: { filter: filter, report: $('#report').val() },
            success: function (response) {
                resolve(response);
            },
            error: function (xhr, status, error) {
                console.error("AJAX Error: " + status + error);
                reject(error);
            }
        });
    });
}

function populate_type_ahead(el, list) {
    el.empty();
    for (let index = 0; index < list.length; index++) {
        const item = list[index];
        el.append(`<div class="w-full px-4 py-2 type_ahead_select_item" data-value="${item.id}"> ${item.select_name} </div>`);
    }
};

$(() => {
    const el = $('#report-account-id');
    el.next().on('input', function () {
        const input = $(this);
        const value = input.val();
        if (!value || value.length < 3) {
            reset_type_ahead(el, false, 'No Accounts Found');
            return;
        }

        // return;
        search_accounts(value)
            .then((resp) => {
                if (!resp || resp.length < 1) {
                    reset_type_ahead(el, false, 'No Accounts Found');
                    return;
                }
                const select_list = input.next();
                populate_type_ahead(select_list, resp);
                const acc = resp.find((ac) => ac.select_name == value.trim());
                if (acc) {
                    el.val(acc.id).trigger('change');
                    input.next().removeClass('active');
                };
            }).catch(err => {
                console.error(err)
            });
    });

    const $type_ahead_input = $('.type_ahead_input');
    $type_ahead_input.on('focus', function () {
        const input = $(this);
        // input.prev().val('');
        input.next().addClass('active');
    });
    $type_ahead_input.on('blur', function () {
        const input = $(this);
        setTimeout(() => {
            input.next().removeClass('active');
        }, 300);
    });

    $('.type_ahead_select_list').on('click', function (event) {
        const item = $(event.target);
        if (!item.hasClass('type_ahead_select_item')) return;
        const val = item.attr('data-value');

        const parent = item.parent().parent();
        parent.children('.type_ahead_input').val(item.text().trim());
        parent.children('.type_ahead_select_list').empty().append('<div class="px-4 placeholder"> Please Type To Filter Results </div>');
        parent.children('.type_ahead_select_value').val(val).trigger('change');
    });

    function redenerReport(data, urls = []) {
        // console.log(data)
        const dialog = $('#report-viewer-modal .modal-dialog');
        dialog.removeClass('max-w-xl');
        const modalBody = $('#report-viewer-modal .modal-body');
        // Create the iframe element
        const iframe = $('<iframe>', {
            id: 'report-container',
            frameborder: 0,
            width: '100%', // Set desired width
            height: '44rem' // Set desired height
        });

        // Append the iframe to the div
        modalBody.empty().html(iframe);

        // Get the iframe's document object
        const iframeDoc = iframe[0].contentDocument || iframe[0].contentWindow.document;
        // console.log(iframeDoc)

        // Write the HTML content to the iframe
        const serializer = new XMLSerializer();
        iframeDoc.open();
        iframeDoc.write(serializer.serializeToString(data));
        iframeDoc.close();

        const modalFooter = $('#report-viewer-modal .modal-footer');
        if (urls.length < 1) modalFooter.empty().addClass('hidden');
        else {
            modalFooter.empty().removeClass('hidden');
            const div = $('<div></div>', {
                class: 'w-full flex justify-end gap-4'
            });
            div.html(`
                <a href="${urls[0]}" download class="btn btn_outlined btn_info uppercase"><i class="text-xl mr-2 las la-download"></i> CSV</a>
                <a href="${urls[1]}" download class="btn btn_outlined btn_success uppercase"><i class="text-xl mr-2 las la-download"></i> EXCEL</a>
                `);
            modalFooter.html(div);
        }

        iframe.on('load', function () {
            const style = iframeDoc.createElement('style');
            style.textContent = `
                html {
                    background-color: rgb(245, 245, 244);
                    padding: 1rem;
                }
                body {
                    background-color: white;
                    width: fit-content;
                    margin: auto;
                    border-radius: 0.25rem;
                    padding: 0.5rem;
                    box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
                }
                table {
                    border-collapse: collapse; /* Ensures borders don't have gaps between cells */
                    width: 100%; /* Optional: full-width table */
                }

                table, th, td {
                    border: 1px solid #aaaaaa;
                }
                th, td {
                    padding: 4px; /* Optional: adds padding inside the cells */
                }
            `;

            iframeDoc.head.appendChild(style);
        });
    }

    function renderError(message) {
        const dialog = $('#report-viewer-modal .modal-dialog');
        dialog.addClass('max-w-xl');
        const modalBody = $('#report-viewer-modal .modal-body');
        const modalFooter = $('#report-viewer-modal .modal-footer');
        modalBody.empty().html(`<p class="text-center p-5">${message}</p>`);
        modalFooter.empty().html('<div class="w-full flex justify-end"><button class="btn btn_secondary uppercase" data-dismiss="modal">Close</button></div>');
    }

    function downloadURLs(url) {
        const csvURL = new URL(url);
        const excelURL = new URL(url);
        const pathname = csvURL.pathname.split('.')[0];
        csvURL.pathname = pathname + '.csv';
        excelURL.pathname = pathname + '.xlsx';
        return [csvURL.toString(), excelURL.toString()];
    }

    const reportForm = $('.reports-form');
    reportForm.on('ajax:beforeSend', function () {
        $('#spinner-modal-trigger').trigger('click');
    });
    reportForm.on('ajax:success', function (event) {
        $('#spinner-modal button').trigger('click');
        const [data, status, xhr] = event.detail;
        // console.log("Response Data:", data);
        // console.log("Status:", status);
        // console.log("XHR Object:", xhr);
        redenerReport(data, downloadURLs(xhr.responseURL));
        $('#modal-trigger').trigger('click');
    });
    reportForm.on('ajax:error', function (event) {
        const error = event.originalEvent.detail[2];
        let errorText = 'Error While generating report';
        if (error && error.responseText) {
            const data = JSON.parse(error.responseText);
            if (data.error) errorText = data.error
        }
        $('#spinner-modal button').trigger('click');
        renderError(errorText);
        $('#modal-trigger').trigger('click');
    });

    reportForm.on('ajax:complete', function (event) {
        // Re-enable the submit button after the request completes
        $(event.target).children('button[type="submit"]').prop('disabled', false);
    });
});
